import React from 'react';
import './rapport-list.css';
import RapportItem from '../Rapport-item/Rapport-item';

const RapportList = () => {
    return (
        <div id='rapport-list'>
            <RapportItem start={1} end={2018} duration={1} delay={0.2} text={"année de création"} />
            <RapportItem start={1} end={20000} duration={2} delay={0.2} text={"personnes sensibilisés"} />
            <RapportItem start={1} end={3} duration={3} delay={0.2} text={"villes bénéficiaires"} />
        </div>
    );
}

export default RapportList;
