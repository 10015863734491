import React from 'react';
import {useRef} from 'react';
import './contact-form.css'; // Ajoutez un fichier CSS pour des styles personnalisés
import emailjs from '@emailjs/browser';

const ContactForm = () => {
    
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()
    
    emailjs
      .sendForm('service_lxpq3kp', 'template_vye9x2d', form.current, {
        publicKey: 'Rq-wF3G2pt_MnxSUx',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    
    };
    
    return (
        <div id="contact-form" className="container mt-4 p-4">
            <div className="left">
                <h2>Contactez-nous</h2>
                <address>Balise, Port-Gentil B.P. 2061 - Gabon</address>
                <p>Tel : +241 77 52 62 55</p>
                <p>Whatsapp : +241 76 29 03 10</p>
                <p>Récépissé provisoire : N°0021/G8/2018</p>
                <strong>Pour faire un don :</strong>
                <p>UBA GABON : GA2140025 05802 80206400007 31 </p>
            </div>

            <form className="right" ref={form} onSubmit={sendEmail}>
                <div className="form-group">
                    <label htmlFor="name">Nom</label>
                    <input type="text" id="name" name='user_name' className="form-control" placeholder="Votre nom" />
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name='user_email' id="email" className="form-control" placeholder="Votre email" />
                </div>

                <div className="form-group">
                    <label htmlFor="subject">Sujet</label>
                    <input type="text" id="subject" className="form-control"  placeholder="Votre sujet" />
                </div>

                <div className="form-group">
                    <label htmlFor="message" >Message</label>
                    <textarea name='message' id="message" className="form-control" placeholder="Votre message" rows="4" />
                </div>

                <button type="submit" className="btn btn-primary">Envoyer</button>
            </form>
        </div>
    );
}

export default ContactForm;
