import React from 'react';
import ProjectItem from '../Project-item/Project-item';
import './project-list.css'
import Projet1 from '../assets/projet-1.jpeg';
import Projet2 from '../assets/projet-2.jpeg';
import Projet3 from '../assets/projet-3.jpeg';
import Projet4 from '../assets/projet-4.jpeg';

const ProjectList = () => {

    const projects = [
        {
            title: "MON CORPS, MON CHOIX, MES DROITS ",
            img: Projet4
        },
        {
            title: "PERMIS DE CONDUIRE NUMERIQUE ",
            img: Projet1
        },
        {
            title: "RESPONSABLE E-COMMERCE",
            img: Projet2
        },
        {
            title: "TECHNICIENNE COURANT FAIBLE",
            img: Projet3
        }
    ]
    return (
        <div id='project-list'>
            {
                projects.map((project, key) => (
                    <ProjectItem title={project.title} img={project.img} key={key}/>
                ))
            }
        </div>
    );
}

export default ProjectList;
