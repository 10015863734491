import React from 'react';
import './gallery.css';
import Image from './Image/Image';
import photo1 from './assets/photo1.jpg'
import photo2 from './assets/photo2.jpg'
import photo3 from './assets/photo3.jpg'
import photo4 from './assets/photo4.jpg'
import photo5 from './assets/photo5.jpg'
import photo6 from './assets/photo6.jpg'

const Gallery = ({imageList}) => {

    const images = [
        {
            image: photo1,
        },
        {
            image: photo2,
        },
        {
            image: photo3,
        },
        {
            image: photo4,
        },
        {
            image: photo5,
        },
        {
            image: photo6,
        },
 
    ]


    const images_sponsors = [
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },
        {
            image: "https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f",
        },


        
    ]
    return (

        <div id='gallery'>
            {imageList == "images_sponsors"  ? (
                images_sponsors.map((image, index) => (
                    <Image key={index} image={image.image} />
                ))
            ): (
                images.map((image, index) => (
                    <Image key={index} image={image.image} />
                ))
            )}

    </div>
    );
}

export default Gallery;
