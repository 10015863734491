import React from 'react';
import './image.css'

const Image = ({image}) => {
    return (
        <div id='image'>
            <img src={image}/>
        </div>
    );
}

export default Image;
