import React from 'react';
import './rapport.css'
import RapportList from './Rapport-list/Rapport-list';

const Rapport = () => {
    return (
        <div id='rapport'>
            <h1>NOS CHIFFRES</h1>
            <p>Autonomiser les jeunes et les femmes par la promotion de l'utilisation responsable des technologies et des médias.</p>
            <i className="bi bi-plus-circle"></i>
            <RapportList/>
        </div>
    );
}

export default Rapport;
