import React from 'react';
import './rapport-item.css';
import CounterUpPage from './CounterUpPage';


const RapportItem = ({start,end,duration,delay,text}) => {
    return (
        <div id='rapport-item'>
            <CounterUpPage start={start} end={end} duration={duration} delay={delay}/>
            <p>{text}</p>
        </div>
    );
}

export default RapportItem;
