import React from 'react';
import './project-item.css';

const ProjectItem = ({title,img="https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8f"}) => {
    return (
        <div id='project-item'>
            <div className='image'>
            <img  src={img} />
            <div className="content">
                <h1>{title}</h1>
            </div>
            </div>
            
        </div>
    );
}

export default ProjectItem;
