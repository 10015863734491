import logo from './logo.svg';
import './App.css';
import ContactForm from './components/Contact-form/Contact-Form';
import Header from './components/Header/Header';
import Projects from './components/Projects/Projects';
import Rapport from './components/Rapport/Rapport';
import Gallery from './components/Gallery/Gallery';
import Partners from './components/Partners/Partners';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">
      <Header/>
      <main>
      <Projects/>
      <Rapport/>
      <Gallery/>
      <Partners/>
      <ContactForm/>
      <Footer/>
      </main>
      
    </div>
  );
}

export default App;
