import React ,{useState} from 'react';
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger/src';

const CounterUpPage = ({start,end,duration,delay}) => {
    const [counterOn, setCounterOn] = useState(false);    
    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
        <div style={{width: '100%', color: '#E8772B'}}>
        <h1>
            {counterOn &&
            <CountUp start={start} end={end} duration={duration} delay={delay} separator=""/>
            }
         </h1>
        </div>
        </ScrollTrigger>
    );
}

export default CounterUpPage;
